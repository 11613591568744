<template>
  <div class="h100 vh100 center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <s-logo />
      <h1 v-if="$mq != 'sm'" class="d-flex green--text font-48">
        <div class="weight-300">secur</div>
        <div class="bold">Meet</div>
      </h1>
      <div class="missingText red--text" :class="{ 'green--text': green }">
        <div v-if="serverError">{{ $t("server." + serverError) }}</div>
      </div>
      <s-text-field
        block
        disabled
        v-model="email"
        :label="$t('registration.email')"
      ></s-text-field>
      <s-text-field
        block
        bar
        type="password"
        v-model="password1"
        :label="$t('resetPassword.password')"
      ></s-text-field>
      <s-text-field
        block
        details
        type="password"
        :error="errorMessage"
        v-model="password2"
        :label="$t('resetPassword.repeat')"
      ></s-text-field>
      <s-btn type="submit" block class="green mt-15" height="40">
        {{ $t("registration.submit") }}
      </s-btn>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      message: "",
      success: "",
      email: "",
      password1: "",
      password2: "",
      serverError: "",
      errorMessage: "",
      green: false,
    };
  },
  methods: {
    validateOnError() {
      if (this.error) this.validate();
    },
    async post() {
      if (!this.validate()) return;
      let response = await this.$store.dispatch("auth/activate", {
        password1: this.password1,
        password2: this.password2,
      });
      if (response.status === "ok") {
        this.$store.dispatch("user/getUserInformation");
        this.$store.dispatch("rooms/getRoomsInformation");
        this.$store.dispatch("attendees/getAttendeesInformation");
        this.$store.dispatch("user/getTimezones");
        this.$router.push("/");
      }
      if (response.status === "error") {
        this.serverError = response.errorCode;
      }
    },
    validate() {
      this.error = false;
      this.errorMessage = "";
      this.serverError = "";

      if (this.password1 !== this.password2) {
        this.error = true;
        this.errorMessage = this.$t("resetPassword.password_no_not_match");
        return;
      }

      if ((this.password1.length || this.password2.length) < parseInt(8)) {
        this.error = true;
        this.errorMessage = this.$t("server.password_length_error");
        return;
      }
      return true;
    },
    async getEmail() {
      let response = await this.$store.dispatch("auth/getEmail")
      this.email = response.email;
    },
  },
  mounted() {
    this.getEmail();
  },
};
</script>


<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}
</style>
