<template>
  <ActivationComp />
</template>

<script>
import ActivationComp from "@/components/Auth/Activation/Activation.vue";

export default {
  name: "Activation",
  components: {
    ActivationComp,
  },
};
</script>

